// exerciseLists.js

// Meditation list
export const meditationList = [
  { name: "5 Minute Mindfulness Meditation", link: "https://www.youtube.com/watch?v=ssss7V1_eyA", videoId: "ssss7V1_eyA", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "A Ten Minute Guided Meditation to Clear Your Mind", link: "https://www.youtube.com/watch?v=ez3GgRqhNvA", videoId: "ez3GgRqhNvA", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "5 Minute Meditation Before Sleep", link: "https://www.youtube.com/watch?v=2K4T9HmEhWE", videoId: "2K4T9HmEhWE", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "10 Minute Meditation for Before You Sleep", link: "https://www.youtube.com/watch?v=ZgPHetPG4MY", videoId: "ZgPHetPG4MY", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "A 10-Minute Journey to Inner Peace | Guided Meditation", link: "https://www.youtube.com/watch?v=xv-ejEOogaA", videoId: "xv-ejEOogaA", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "Powerful 6-phase Morning Meditation", link: "https://www.youtube.com/watch?v=DdUm9U9w9W0", videoId: "DdUm9U9w9W0", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "10 Minute Guided Breathing Meditation", link: "https://www.youtube.com/watch?v=VUjiXcfKBn8", videoId: "VUjiXcfKBn8", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "A Great 5 Minute Morning Meditation to Start Your Day", link: "https://www.youtube.com/watch?v=YD5W5eZy90c", videoId: "YD5W5eZy90c", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "5 Minute Morning Meditation", link: "https://www.youtube.com/watch?v=HNab2YqCCiM", videoId: "HNab2YqCCiM", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "A Powerful 20 Minute Guided Meditation", link: "https://www.youtube.com/watch?v=zYpvOotEbhQ", videoId: "zYpvOotEbhQ", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "Clear Your Mind, 5 Minute Meditation, Calm & Relaxed", link: "https://www.youtube.com/watch?v=nlEoLYREbXo", videoId: "nlEoLYREbXo", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious"] },
  { name: "Guided Meditation for Inner Peace and Calm", link: "https://www.youtube.com/watch?v=pAEioF7FaWY", videoId: "pAEioF7FaWY", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious"] },
  { name: "Quiet the Mind & Becoming Present, 10 Minute Guided Meditation", link: "https://www.youtube.com/watch?v=LLeqY9ingRY", videoId: "LLeqY9ingRY", tags: ["Stressed", "Overwhelmed", "Anxious", "Content", "Joyful", "Excited", "Grateful"] },
  { name: "10 Minute Meditation for a Relaxed Mind & Body", link: "https://www.youtube.com/watch?v=BFqs75OW-7I", videoId: "BFqs75OW-7I", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] },
  { name: "Guided Meditation for Relaxation", link: "https://www.youtube.com/watch?v=krBvzDlL0mM", videoId: "krBvzDlL0mM", tags: ["Relaxed", "Stressed", "Frustrated", "Overwhelmed", "Sad", "Tired", "Bored", "Anxious", "Mindfulness", "Excited", "Grateful", "Joyful", "Content", "Motivated"] }
];

// Journaling prompts
export const journalingList = [
  { prompt: "What brought you joy today, and how can you create more of these moments", tags: ["Joyful", "Positivity", "Mindfulness"] },
  { prompt: "Describe a recent joyful experience in detail. How did it impact your day?", tags: ["Positivity", "Emotional Intelligence", "Joyful"] },
  { prompt: "What does joy mean to you, and how do you cultivate it in your life?", tags: ["Joyful", "Purpose", "Mindfulness"] },
  { prompt: "Write about three things that consistently make you feel joyful.", tags: ["Joyful", "Mindfulness", "Positivity"] },
  { prompt: "Reflect on a time when you spread joy to others. How did it make you feel?", tags: ["Joyful", "Connection", "Emotional Intelligence"] },
  { prompt: "What are you most excited about right now, and why?", tags: ["Excited", "Purpose", "Positivity"] },
  { prompt: "How does excitement influence your actions and decisions?", tags: ["Excited", "Emotional Intelligence", "Focus"] },
  { prompt: "Write about a future event or goal you're excited about. What steps will you take to prepare?", tags: ["Excited", "Adaptability", "Focus"] },
  { prompt: "Reflect on the last time you felt genuinely excited. What sparked that feeling?", tags: ["Excited", "Mindfulness", "Emotional Intelligence"] },
  { prompt: "How can you channel your excitement into positive outcomes?", tags: ["Excited", "Purpose", "Positivity"] },
  { prompt: "List five things you are grateful for today. How do they contribute to your well-being?", tags: ["Grateful", "Mindfulness", "Positivity"] },
  { prompt: "Describe a person who has had a positive impact on your life. How can you show your gratitude?", tags: ["Grateful", "Connection", "Emotional Intelligence"] },
  { prompt: "Write about a challenging experience that ultimately made you feel grateful", tags: ["Grateful", "Adaptability", "Self-Compassion"] },
  { prompt: "How does practicing gratitude change your perspective on life?", tags: ["Grateful", "Emotional Intelligence", "Positivity"] },
  { prompt: "What small moments from today are you most thankful for?", tags: ["Grateful", "Mindfulness", "Positivity"] },
  { prompt: "Describe a moment when you felt completely relaxed. What contributed to that feeling?", tags: ["Relaxed", "Mindfulness", "Stress Management"] },
  { prompt: "What activities help you unwind and relax? How can you incorporate them more often?", tags: ["Relaxed", "Stress Management", "Self-Compassion"] },
  { prompt: "How does relaxation affect your mental and emotional state?", tags: ["Relaxed", "Emotional Intelligence", "Mindfulness"] },
  { prompt: "Reflect on the balance between work and relaxation in your life. How can you improve it?", tags: ["Relaxed", "Adaptability", "Stress Management"] },
  { prompt: "Write about the last time you took a break and how it benefited you", tags: ["Relaxed", "Mindfulness", "Self-Compassion"] },
  { prompt: "What makes you feel content with your life right now?", tags: ["Content", "Positivity", "Mindfulness"] },
  { prompt: "How do you define contentment? How can you cultivate more of it?", tags: ["Content", "Emotional Intelligence", "Purpose"] },
  { prompt: "Reflect on a recent moment of contentment. What factors contributed to it?", tags: ["Content", "Mindfulness", "Positivity"] },
  { prompt: "How do you balance the desire for growth with the need for contentment?", tags: ["Content", "Adaptability", "Purpose"] },
  { prompt: "Write about how being content affects your overall well-being", tags: ["Content", "Self-Compassion", "Emotional Intelligence"] },
  { prompt: "How does being tired affect your mood and productivity?", tags: ["Tired", "Emotional Intelligence", "Stress Management"] },
  { prompt: "What can you do to ensure you get enough rest and relaxation?", tags: ["Tired", "Mindfulness", "Self-Compassion"] },
  { prompt: "Reflect on the last time you pushed through exhaustion. Was it worth it?", tags: ["Tired", "Stress Management", "Emotional Intelligence"] },
  { prompt: "What changes can you make to your routine to reduce feelings of tiredness?", tags: ["Tired", "Adaptability", "Stress Management"] },
  { prompt: "Write about how you feel after getting a good night's sleep", tags: ["Tired", "Mindfulness", "Self-Compassion"] },
  { prompt: "What activities could you explore to make your life more exciting?", tags: ["Bored", "Purpose", "Adaptability"] },
  { prompt: "Reflect on the last time you felt bored. How did you overcome it?", tags: ["Bored", "Adaptability", "Positivity"] },
  { prompt: "How can you turn boredom into an opportunity for creativity?", tags: ["Bored", "Focus", "Positivity"] },
  { prompt: "What does boredom teach you about your needs and desires?", tags: ["Bored", "Emotional Intelligence", "Self-Compassion"] },
  { prompt: "Write about a new hobby or interest you want to try", tags: ["Bored", "Purpose", "Adaptability"] },
  { prompt: "What are the main sources of stress in your life, and how can you address them?", tags: ["Stressed", "Focus", "Stress Management"] },
  { prompt: "Describe a recent stressful situation and how you handled it. What would you do differently?", tags: ["Stressed", "Emotional Intelligence", "Stress Management"] },
  { prompt: "How do you usually cope with stress? Are these strategies effective?", tags: ["Stressed", "Self-Compassion", "Stress Management"] },
  { prompt: "What steps can you take to reduce stress in your daily life?", tags: ["Stressed", "Mindfulness", "Adaptability"] },
  { prompt: "Write about a time when you successfully managed stress. What helped you stay calm?", tags: ["Stressed", "Mindfulness", "Stress Management"] },
  { prompt: "What is causing you to feel anxious right now? How can you manage it?", tags: ["Anxious", "Stress Management", "Emotional Intelligence"] },
  { prompt: "Write about a situation where your anxiety turned out to be unfounded. What did you learn?", tags: ["Anxious", "Adaptability", "Emotional Intelligence"] },
  { prompt: "How do you usually respond to anxiety? What techniques help you calm down?", tags: ["Anxious", "Self-Compassion", "Stress Management"] },
  { prompt: "What positive actions can you take to reduce your anxiety?", tags: ["Anxious", "Mindfulness", "Stress Management"] },
  { prompt: "Reflect on a time when you overcame anxiety. How did you do it?", tags: ["Anxious", "Adaptability", "Emotional Intelligence"] },
  { prompt: "What is making you feel sad today? How can you nurture yourself during this time?", tags: ["Sad", "Self-Compassion", "Emotional Intelligence"] },
  { prompt: "Reflect on a time when you felt sad but eventually found peace. What helped you?", tags: ["Sad", "Positivity", "Self-Compassion"] },
  { prompt: "How do you usually process sadness? Are there healthier ways to cope?", tags: ["Sad", "Self-Compassion", "Emotional Intelligence"] },
  { prompt: "What lessons have you learned from past experiences of sadness?", tags: ["Sad", "Emotional Intelligence", "Adaptability"] },
  { prompt: "Write about something that brings you comfort when you are sad.", tags: ["Sad", "Mindfulness", "Self-Compassion"] },
  { prompt: "What is the source of your frustration, and how can you address it constructively?", tags: ["Frustrated", "Emotional Intelligence", "Adaptability"] },
  { prompt: "Describe a time when you turned frustration into a positive outcome. What did you learn?", tags: ["Frustrated", "Adaptability", "Positivity"] },
  { prompt: "How can you better manage frustration in the future?", tags: ["Frustrated", "Emotional Intelligence", "Stress Management"] },
  { prompt: "What steps can you take to move past your current frustration?", tags: ["Frustrated", "Adaptability", "Focus"] },
  { prompt: "Reflect on how frustration affects your interactions with others. How can you improve?", tags: ["Frustrated", "Connection", "Emotional Intelligence"] },
  { prompt: "What tasks are overwhelming you right now? How can you prioritize them?", tags: ["Overwhelmed", "Focus", "Stress Management"] },
  { prompt: "Write about a time when you felt overwhelmed but managed to stay calm. What strategies did you use?", tags: ["Overwhelmed", "Mindfulness", "Stress Management"] },
  { prompt: "How can you break down your overwhelming tasks into smaller, manageable steps?", tags: ["Overwhelmed", "Focus", "Adaptability"] },
  { prompt: "Reflect on what triggers your feelings of being overwhelmed. How can you avoid these triggers?", tags: ["Overwhelmed", "Emotional Intelligence", "Stress Management"] },
  { prompt: "What self-care practices can help you cope with feeling overwhelmed?", tags: ["Overwhelmed", "Self-Compassion", "Stress Management"] },
  { prompt: "What is driving your motivation right now, and how can you sustain it?", tags: ["Motivated", "Focus", "Purpose"] },
  { prompt: "Write about a goal you are currently motivated to achieve. What steps will you take?", tags: ["Motivated", "Purpose", "Focus"] },
  { prompt: "How does motivation affect your daily actions and decisions?", tags: ["Motivated", "Emotional Intelligence", "Positivity"] },
  { prompt: "Reflect on a time when your motivation led to a significant achievement. What did you learn from that experience?", tags: ["Motivated", "Purpose", "Adaptability"] },
  { prompt: "What personal strengths have you relied on to stay motivated, and how can you continue to nurture these strengths?", tags: ["Motivated", "Self-Compassion", "Emotional Intelligence"] }
];

// Physical exercises
export const physicalExerciseList = [
  { name: "Dance to a favorite song for 10 minutes in your living room", tags: ["Joyful", "Mindfulness", "Positivity"] },
  { name: "Jumping Jacks for 5 minutes", tags: ["Joyful", "Mindfulness", "Positivity"] },
  { name: "A 20-minute brisk walk, focusing on enjoying the surroundings.", tags: ["Joyful", "Mindfulness", "Connection"] },
  { name: "10 minutes of high-knee jogging in place", tags: ["Excited", "Focus", "Adaptability"] },
  { name: "10 minutes of bodyweight exercises, including push-ups and squats", tags: ["Excited", "Purpose", "Positivity"] },
  { name: "15 minutes of gentle stretching, including Forward Bend and Butterfly Pose", tags: ["Grateful", "Mindfulness", "Self-Compassion"] },
  { name: "A 20-minute walk, reflecting on things you are grateful for", tags: ["Grateful", "Mindfulness", "Positivity"] },
  { name: "10 minutes of deep breathing exercises combined with simple stretches like Neck Rolls and Shoulder Shrugs", tags: ["Relaxed", "Mindfulness", "Stress Management"] },
  { name: "10 minutes of gentle yoga, focusing on poses like Cat-Cow and Child's Pose", tags: ["Relaxed", "Mindfulness", "Stress Management"] },
  { name: "A 20-minute leisurely bike ride at a comfortable pace", tags: ["Content", "Mindfulness", "Positivity"] },
  { name: "15 minutes of light stretching, including Hamstring Stretch and Seated Twist", tags: ["Content", "Mindfulness", "Stress Management"] },
  { name: "10 minutes of restorative yoga, including Legs-Up-the-Wall and Reclining Bound Angle Pose", tags: ["Tired", "Self-Compassion", "Stress Management"] },
  { name: "A 15-minute gentle walk, maintaining a slow and relaxed pace", tags: ["Tired", "Mindfulness", "Self-Compassion"] },
  { name: "15 minutes of basic bodyweight exercises, such as squats and lunges", tags: ["Bored", "Adaptability", "Positivity"] },
  { name: "20 minutes of a simple dance routine or a fun, low-impact cardio workout", tags: ["Bored", "Positivity", "Connection"] },
  { name: "15 minutes of fast-paced walking or light jogging to release tension", tags: ["Stressed", "Focus", "Stress Management"] },
  { name: "10 minutes of yoga with stress-relief poses like Forward Fold and Child's Pose", tags: ["Stressed", "Mindfulness", "Stress Management"] },
  { name: "10 minutes of gentle yoga focusing on relaxation, such as Child's Pose and Legs-Up-the-Wall", tags: ["Anxious", "Self-Compassion", "Stress Management"] },
  { name: "A 15-minute slow-paced walk, focusing on deep, calm breathing", tags: ["Anxious", "Mindfulness", "Stress Management"] },
  { name: "20 minutes of moderate-paced walking or cycling to improve mood", tags: ["Sad", "Positivity", "Emotional Intelligence"] },
  { name: "15 minutes of simple yoga poses like Bridge Pose and Legs-Up-the-Wall", tags: ["Sad", "Self-Compassion", "Mindfulness"] },
  { name: "15 minutes of high-energy exercises, such as jumping rope or fast-paced marching in place", tags: ["Frustrated", "Stress Management", "Focus"] },
  { name: "10 minutes of basic strength training with bodyweight exercises like push-ups and planks", tags: ["Frustrated", "Focus", "Adaptability"] },
  { name: "10 minutes of guided deep breathing combined with gentle stretching", tags: ["Overwhelmed", "Mindfulness", "Stress Management"] },
  { name: "15 minutes of restorative yoga with poses like Legs-Up-the-Wall and Savasana", tags: ["Overwhelmed", "Stress Management", "Self-Compassion"] },
  { name: "10 minutes of interval training with basic exercises like burpees and mountain climbers", tags: ["Motivated", "Purpose", "Focus"] },
  { name: "15 minutes of household chores like vacuuming or gardening, using the time to stay active and engaged", tags: ["Motivated", "Purpose", "Adaptability"] }
];

// Affirmations
export const affirmationList = [
  { affirmation: "I embrace the joy in each moment", tags: ["Joyful", "Positivity", "Mindfulness"] },
  { affirmation: "Happiness flows through me effortlessly", tags: ["Positivity", "Emotional Intelligence", "Joyful"] },
  { affirmation: "I radiate happiness and positivity", tags: ["Joyful", "Positivity", "Connection"] },
  { affirmation: "I am grateful for the joy that fills my life", tags: ["Joyful", "Mindfulness", "Emotional Intelligence"] },
  { affirmation: "Every day is a new opportunity to experience joy", tags: ["Joyful", "Purpose", "Positivity"] },
  { affirmation: "I am excited about the endless possibilities ahead", tags: ["Excited", "Purpose", "Adaptability"] },
  { affirmation: "My enthusiasm is contagious and energizing", tags: ["Excited", "Positivity", "Connection"] },
  { affirmation: "I welcome new adventures with open arms", tags: ["Excited", "Adaptability", "Purpose"] },
  { affirmation: "I am passionate about what I do, and it shows", tags: ["Excited", "Purpose", "Positivity"] },
  { affirmation: "Excitement fuels my creativity and success", tags: ["Excited", "Focus", "Positivity"] },
  { affirmation: "I am grateful for the abundance in my life", tags: ["Grateful", "Mindfulness", "Emotional Intelligence"] },
  { affirmation: "I recognize and appreciate the goodness in others", tags: ["Grateful", "Connection", "Positivity"] },
  { affirmation: "Gratitude fills my heart and guides my actions", tags: ["Grateful", "Emotional Intelligence", "Positivity"] },
  { affirmation: "I am thankful for the lessons life teaches me", tags: ["Grateful", "Adaptability", "Positivity"] },
  { affirmation: "Gratitude brings me peace and contentment", tags: ["Grateful", "Mindfulness", "Stress Management"] },
  { affirmation: "I am calm, peaceful, and relaxed", tags: ["Relaxed", "Mindfulness", "Stress Management"] },
  { affirmation: "I release tension and welcome tranquility", tags: ["Relaxed", "Stress Management", "Emotional Intelligence"] },
  { affirmation: "I am in tune with the calm energy around me.", tags: ["Relaxed", "Emotional Intelligence", "Mindfulness"] },
  { affirmation: "Relaxation is my natural state of being", tags: ["Relaxed", "Mindfulness", "Stress Management"] },
  { affirmation: "I allow myself to unwind and rejuvenate", tags: ["Relaxed", "Mindfulness", "Self-Compassion"] },
  { affirmation: "I am at peace with where I am right now", tags: ["Content", "Self-Compassion", "Mindfulness"] },
  { affirmation: "I trust in the timing of my life", tags: ["Content", "Adaptability", "Purpose"] },
  { affirmation: "I find contentment in the present moment", tags: ["Content", "Mindfulness", "Positivity"] },
  { affirmation: "Contentment flows through me effortlessly", tags: ["Content", "Emotional Intelligence", "Stress Management"] },
  { affirmation: "I am grateful for the simple joys in life", tags: ["Content", "Mindfulness", "Positivity"] },
  { affirmation: "I allow myself to rest and recharge", tags: ["Tired", "Self-Compassion", "Stress Management"] },
  { affirmation: "My body deserves care, and I listen to its needs", tags: ["Tired", "Mindfulness", "Emotional Intelligence"] },
  { affirmation: "Rest is essential to my well-being, and I honor it.", tags: ["Tired", "Self-Compassion", "Mindfulness"] },
  { affirmation: "I give myself permission to slow down and recover.", tags: ["Tired", "Self-Compassion", "Stress Management"] },
  { affirmation: "I restore my energy by nurturing my body and mind", tags: ["Tired", "Emotional Intelligence", "Self-Compassion"] },
  { affirmation: "I create excitement and joy in the simplest of things", tags: ["Bored", "Positivity", "Adaptability"] },
  { affirmation: "I explore new ways to engage and grow", tags: ["Bored", "Adaptability", "Purpose"] },
  { affirmation: "Boredom is a sign to try something new.", tags: ["Bored", "Adaptability", "Positivity"] },
  { affirmation: "I use boredom as a catalyst for creativity", tags: ["Bored", "Focus", "Adaptability"] },
  { affirmation: "Every moment offers a chance to learn and discover", tags: ["Bored", "Purpose", "Positivity"] },
  { affirmation: "I allow myself to rest and recharge", tags: ["Stressed", "Focus", "Stress Management"] },
  { affirmation: "My body deserves care, and I listen to its needs", tags: ["Stressed", "Mindfulness", "Stress Management"] },
  { affirmation: "Rest is essential to my well-being, and I honor it.", tags: ["Stressed", "Adaptability", "Stress Management"] },
  { affirmation: "I give myself permission to slow down and recover.", tags: ["Stressed", "Emotional Intelligence", "Stress Management"] },
  { affirmation: "I restore my energy by nurturing my body and mind", tags: ["Stressed", "Mindfulness", "Stress Management"] },
  { affirmation: "I am safe, and I trust the process of life", tags: ["Anxious", "Stress Management", "Mindfulness"] },
  { affirmation: "I focus on what I can control and release the rest", tags: ["Anxious", "Adaptability", "Emotional Intelligence"] },
  { affirmation: "I am in control of my thoughts and emotions.", tags: ["Anxious", "Emotional Intelligence", "Stress Management"] },
  { affirmation: "I trust myself to handle whatever comes my way", tags: ["Anxious", "Self-Compassion", "Stress Management"] },
  { affirmation: "I am grounded, centered, and calm", tags: ["Anxious", "Mindfulness", "Stress Management"] },
  { affirmation: "I give myself permission to feel and heal", tags: ["Sad", "Self-Compassion", "Emotional Intelligence"] },
  { affirmation: "I find comfort in my inner strength", tags: ["Sad", "Positivity", "Emotional Intelligence"] },
  { affirmation: "I am allowed to take my time to heal", tags: ["Sad", "Self-Compassion", "Mindfulness"] },
  { affirmation: "I acknowledge my emotions and let them pass", tags: ["Sad", "Emotional Intelligence", "Stress Management"] },
  { affirmation: "I am strong enough to overcome this sadness", tags: ["Sad", "Emotional Intelligence", "Positivity"] },
  { affirmation: "I focus on solutions, not problems", tags: ["Frustrated", "Focus", "Adaptability"] },
  { affirmation: "I have the power to turn challenges into opportunities", tags: ["Frustrated", "Adaptability", "Positivity"] },
  { affirmation: "I approach frustration with patience and clarity", tags: ["Frustrated", "Emotional Intelligence", "Stress Management"] },
  { affirmation: "I learn and grow from every experience", tags: ["Frustrated", "Adaptability", "Purpose"] },
  { affirmation: "I trust in my ability to overcome obstacles", tags: ["Frustrated", "Positivity", "Adaptability"] },
  { affirmation: "I break down my tasks into manageable steps", tags: ["Overwhelmed", "Focus", "Stress Management"] },
  { affirmation: "I am in control of my time and energy", tags: ["Overwhelmed", "Adaptability", "Stress Management"] },
  { affirmation: "I prioritize what truly matters and let go of the rest", tags: ["Overwhelmed", "Focus", "Stress Management"] },
  { affirmation: "I am calm, even in the face of many demands", tags: ["Overwhelmed", "Mindfulness", "Stress Management"] },
  { affirmation: "I give myself permission to take it one step at a time", tags: ["Overwhelmed", "Self-Compassion", "Focus"] },
  { affirmation: "I am driven and focused on achieving my goals", tags: ["Motivated", "Focus", "Purpose"] },
  { affirmation: "My determination leads me to success", tags: ["Motivated", "Purpose", "Positivity"] },
  { affirmation: "I am motivated by my passion and purpose.", tags: ["Motivated", "Emotional Intelligence", "Purpose"] },
  { affirmation: "I take inspired action every day", tags: ["Motivated", "Focus", "Positivity"] },
  { affirmation: "I am unstoppable in pursuing my dreams", tags: ["Motivated", "Purpose", "Adaptability"] }
];
