import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = (location) => {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('config', 'G-153QL5FL2N', {
          page_path: location.pathname + location.search,
        });
      }
    };

    handleRouteChange(location); // Track the initial page load
  }, [location]);
}

export default usePageTracking;
