import React, { useState, useEffect } from 'react';
import { RefreshCw, Play } from 'lucide-react';
import { meditationList, physicalExerciseList, journalingList, affirmationList } from '../data/exerciseLists';

const GritUPChallenge = () => {
  const startDate = new Date('2024-09-01');
  const today = new Date();
  const currentDay = Math.floor((today - startDate) / (1000 * 60 * 60 * 24)) + 1;

  const [exercises, setExercises] = useState({
    meditation: null,
    physical: null,
    journaling: null,
    affirmation: null
  });
  const [selectedMood, setSelectedMood] = useState('');
  const [moodResponse, setMoodResponse] = useState('');

  useEffect(() => {
    generateExercises();
  }, []);

  const generateExercises = () => {
    setExercises({
      meditation: meditationList[Math.floor(Math.random() * meditationList.length)],
      physical: physicalExerciseList[Math.floor(Math.random() * physicalExerciseList.length)],
      journaling: journalingList[Math.floor(Math.random() * journalingList.length)],
      affirmation: null
    });
  };

  const handleMoodSelect = (mood) => {
    setSelectedMood(mood);
    const response = getMoodResponse(mood);
    setMoodResponse(response);
    const moodAffirmations = affirmationList.filter(a => a.tags.includes(mood));
    setExercises(prev => ({ 
      ...prev, 
      affirmation: moodAffirmations[Math.floor(Math.random() * moodAffirmations.length)]
    }));
  };

  const getMoodResponse = (selectedMood) => {
    switch (selectedMood) {
      case 'Joyful':
        return "That's wonderful! Your positive energy can be contagious. Why not spread some of that joy today?";
      case 'Excited':
        return "Fantastic! Channel that excitement into something productive or creative today.";
      case 'Grateful':
        return "Gratitude is a powerful emotion. Take a moment to appreciate the little things in your life.";
      case 'Relaxed':
        return "It's great that you're feeling relaxed. This is a good state of mind for reflection or gentle productivity.";
      case 'Stressed':
        return "I'm sorry you're feeling stressed. Remember to take deep breaths and consider what you can control.";
      case 'Anxious':
        return "Anxiety can be challenging. Remember that your feelings are valid, and it's okay to seek support.";
      default:
        return "Thank you for sharing how you feel. Every emotion is valid and provides an opportunity for growth.";
    }
  };

  const ExerciseCard = ({ title, content, videoId, onRefresh }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-xl font-semibold text-teal-700">{title}</h3>
          {onRefresh && (
            <button onClick={onRefresh} className="text-teal-500 hover:text-teal-700">
              <RefreshCw size={20} />
            </button>
          )}
        </div>
        <p className="mb-4">{content}</p>
        {videoId && (
          <div 
            className="relative cursor-pointer mb-4 w-48 h-27"
            onClick={() => setIsModalOpen(true)}
          >
            <img 
              src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
              alt="Video thumbnail"
              className="w-full h-full rounded-lg object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <Play size={32} className="text-white opacity-80" />
            </div>
          </div>
        )}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg max-w-5xl w-full">
              <div className="relative pb-[56.25%] h-0">
                <iframe
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
              <button 
                onClick={() => setIsModalOpen(false)}
                className="mt-4 px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-4xl font-bold text-teal-700 mb-6">GritUP Challenge - Day {currentDay}</h1>
      <h2 className="text-2xl font-semibold text-teal-600 mb-4">Your Recommended Exercises</h2>

      <ExerciseCard 
        title="Meditation"
        content={exercises.meditation?.name}
        videoId={exercises.meditation?.videoId}
        onRefresh={() => setExercises(prev => ({ ...prev, meditation: meditationList[Math.floor(Math.random() * meditationList.length)] }))}
      />

      <ExerciseCard 
        title="Physical Exercise"
        content={exercises.physical?.name}
        onRefresh={() => setExercises(prev => ({ ...prev, physical: physicalExerciseList[Math.floor(Math.random() * physicalExerciseList.length)] }))}
      />

      <ExerciseCard 
        title="Journaling"
        content={`Reflect on this: ${exercises.journaling?.prompt}`}
        onRefresh={() => setExercises(prev => ({ ...prev, journaling: journalingList[Math.floor(Math.random() * journalingList.length)] }))}
      />

      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h3 className="text-xl font-semibold text-teal-700 mb-4">Affirmation</h3>
        <p className="mb-4">Select your mood to see an affirmation:</p>
        <div className="flex flex-wrap gap-2 mb-4">
          {['Joyful', 'Excited', 'Grateful', 'Relaxed', 'Stressed', 'Anxious'].map((mood) => (
            <button
              key={mood}
              onClick={() => handleMoodSelect(mood)}
              className={`px-3 py-1 rounded ${
                selectedMood === mood ? 'bg-teal-500 text-white' : 'bg-teal-100 text-teal-800'
              } hover:bg-teal-400 transition-colors`}
            >
              {mood}
            </button>
          ))}
        </div>
        {moodResponse && <p className="mb-4">{moodResponse}</p>}
        {exercises.affirmation && <p className="italic">"{exercises.affirmation.affirmation}"</p>}
      </div>

      <button 
        onClick={generateExercises} 
        className="mt-6 px-6 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors text-lg font-semibold"
      >
        Generate New Exercises
      </button>
    </div>
  );
};

export default GritUPChallenge;