import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import TrackedRoutes from './TrackedRoutes';
import logo from './assets/gritup-logo.png'; // Make sure to add your logo to the assets folder

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-sm p-4">
        <div className="container mx-auto flex items-center justify-between">
          <img src={logo} alt="GritUP Logo" className="h-10" />
        </div>
      </header>
      <main className="container mx-auto px-4 py-8">
        <Router>
          <TrackedRoutes />
        </Router>
      </main>
    </div>
  );
}

export default App;