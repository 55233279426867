import React from 'react';
import { Routes, Route } from 'react-router-dom';
import usePageTracking from './hooks/usePageTracking';
import GritUPChallenge from './components/GritUPChallenge';

function TrackedRoutes() {
  usePageTracking();

  return (
    <Routes>
      <Route path="/" element={<GritUPChallenge />} />
      {/* Add other routes here */}
    </Routes>
  );
}

export default TrackedRoutes;
